@font-face {
  font-family: "Poppins";
  src: url("../src/source/poppins.otf");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  user-select: none;
  background-color: #fffcbe;
}

body {
    min-height: 100vh;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

h1 {
    font-size: clamp(3rem, 7vw, 10rem);
}

h2 {
    font-size: clamp(3rem, 6vw, 8rem);
    transform: rotate(-1deg) skew(1deg);
}

h3 {
    font-size: clamp(2rem, 4vw, 8rem);
    transform: rotate(-1deg) skew(1deg);
}

p {
    font-size: clamp(2rem, 8vw, 10rem);
    line-height: 100%;
}

p1 {
    font-size: clamp(2rem, 7vw, 10rem);
    line-height: 100%;
}

p2 {
    font-size: clamp(2rem, 4.4vw, 10rem);
    line-height: 100%;
}

#stroke1 {
    text-shadow:
    -1.7px -1.7px 0 #242022,
     0   -1.7px 0 #242022,
     1.7px -1.7px 0 #242022,
     1.7px  0   0 #242022,
     1.7px  1.7px 0 #242022,
     0    1.7px 0 #242022,
    -1.7px  1.7px 0 #242022,
    -1.7px  0   0 #242022;
}

#connect {
    font-size: clamp(2rem, 3vw, 10rem);

    text-shadow:
    -2px -2px 0 #242022,
     0   -2px 0 #242022,
     2px -2px 0 #242022,
     2px  0   0 #242022,
     2px  2px 0 #242022,
     0    2px 0 #242022,
    -2px  2px 0 #242022,
    -2px  0   0 #242022;
}

#backbutton {
    font-size: clamp(2rem, 3vw, 10rem);
    text-shadow:
    -1.75px -1.75px 0 #242022,
     0   -1.75px 0 #242022,
     1.75px -1.75px 0 #242022,
     1.75px  0   0 #242022,
     1.75px  1.75px 0 #242022,
     0    1.75px 0 #242022,
    -1.75px  1.75px 0 #242022,
    -1.75px  0   0 #242022;
}

#highlight {
    font-size: clamp(2rem, 8vw, 10rem);

}

#highlight1 {
    font-size: clamp(2rem, 8vw, 10rem);

}

#gamediv {
    width:960px;
    height:600px;
}


#stroke {
    
    text-shadow:
    -2px -2px 0 #242022,
     0   -2px 0 #242022,
     2px -2px 0 #242022,
     2px  0   0 #242022,
     2px  2px 0 #242022,
     0    2px 0 #242022,
    -2px  2px 0 #242022,
    -2px  0   0 #242022;
}


@media only screen and (max-height: 700px) {
    #helpbutton {
        font-size: 25px;
    
        text-shadow:
        -1.6px -1.6px 0 #242022,
         0   -1.6px 0 #242022,
         1.6px -1.6px 0 #242022,
         1.6px  0   0 #242022,
         1.6px  1.6px 0 #242022,
         0    1.6px 0 #242022,
        -1.6px  1.6px 0 #242022,
        -1.6px  0   0 #242022;
    }
}



@media only screen and (min-width: 300px) {
#stroke {
        text-shadow:
        -1.5px -1.5px 0 #242022,
         0   -1.5px 0 #242022,
         1.5px -1.5px 0 #242022,
         1.5px  0   0 #242022,
         1.5px  1.5px 0 #242022,
         0    1.5px 0 #242022,
        -1.5px  1.5px 0 #242022,
        -1.5px  0   0 #242022;
        
    }

#title {
        font-size: clamp(3rem, 10vw, 10rem);
        text-shadow:
        -1.5px -1.5px 0 #242022,
         0   -1.5px 0 #242022,
         1.5px -1.5px 0 #242022,
         1.5px  0   0 #242022,
         1.5px  1.5px 0 #242022,
         0    1.5px 0 #242022,
        -1.5px  1.5px 0 #242022,
        -1.5px  0   0 #242022;
}

#connect {
    font-size: clamp(2rem, 3vw, 10rem);
    border-width: 1.5px;
    text-shadow:
        -1.5px -1.5px 0 #242022,
         0   -1.5px 0 #242022,
         1.5px -1.5px 0 #242022,
         1.5px  0   0 #242022,
         1.5px  1.5px 0 #242022,
         0    1.5px 0 #242022,
        -1.5px  1.5px 0 #242022,
        -1.5px  0   0 #242022;
}

#chainchange {
    font-size: clamp(2rem, 3vw, 10rem);
    border-width: 1.5px;
    text-shadow:
        -1.5px -1.5px 0 #242022,
         0   -1.5px 0 #242022,
         1.5px -1.5px 0 #242022,
         1.5px  0   0 #242022,
         1.5px  1.5px 0 #242022,
         0    1.5px 0 #242022,
        -1.5px  1.5px 0 #242022,
        -1.5px  0   0 #242022;
}

#sidebutton {
    font-size: clamp(2rem, 3vw, 10rem);
    border-width: 1.5px;
    text-shadow:
        -1.5px -1.5px 0 #242022,
         0   -1.5px 0 #242022,
         1.5px -1.5px 0 #242022,
         1.5px  0   0 #242022,
         1.5px  1.5px 0 #242022,
         0    1.5px 0 #242022,
        -1.5px  1.5px 0 #242022,
        -1.5px  0   0 #242022;
}


#helpbutton {
    font-size: clamp(2rem, 1.9vw, 10rem);
    border-width: 1.5px;
    text-shadow:
        -1.5px -1.5px 0 #242022,
         0   -1.5px 0 #242022,
         1.5px -1.5px 0 #242022,
         1.5px  0   0 #242022,
         1.5px  1.5px 0 #242022,
         0    1.5px 0 #242022,
        -1.5px  1.5px 0 #242022,
        -1.5px  0   0 #242022;
}

#backbutton {
    font-size: clamp(2rem, 3vw, 10rem);
    border-width: 1.5px;
    text-shadow:
        -1.5px -1.5px 0 #242022,
         0   -1.5px 0 #242022,
         1.5px -1.5px 0 #242022,
         1.5px  0   0 #242022,
         1.5px  1.5px 0 #242022,
         0    1.5px 0 #242022,
        -1.5px  1.5px 0 #242022,
        -1.5px  0   0 #242022;
}

}

@media only screen and (min-width: 720px) {

p1 {
    font-size: clamp(2rem, 4vw, 10rem);
    line-height: 100%;
}

#plus{
    font-size: clamp(3rem, 6vw, 10rem);
}

#stroke {
        text-shadow:
        -1.7px -1.7px 0 #242022,
         0   -1.7px 0 #242022,
         1.7px -1.7px 0 #242022,
         1.7px  0   0 #242022,
         1.7px  1.7px 0 #242022,
         0    1.7px 0 #242022,
        -1.7px  1.7px 0 #242022,
        -1.7px  0   0 #242022;
        
    }

#imgtrf{
    transform:rotate(-1deg) skew(0deg);
}

h2 {
    font-size: clamp(2rem, 6vw, 8rem);
    transform: rotate(-1deg) skew(1deg);
}

    #highlight {
    font-size: clamp(2rem, 4vw, 10rem);

}

    p {
    font-size: clamp(2rem, 3vw, 10rem);
    line-height: 100%;
}

h1 {
    font-size: clamp(3rem, 7vw, 10rem);
    transform: rotate(-3deg) skew(1deg);
    padding-left: .8em; 
    
}

    #title {
        font-size: clamp(3rem, 7vw, 10rem);
        text-shadow:
        -1.7px -1.7px 0 #242022,
         0   -1.7px 0 #242022,
         1.7px -1.7px 0 #242022,
         1.7px  0   0 #242022,
         1.7px  1.7px 0 #242022,
         0    1.7px 0 #242022,
        -1.7px  1.7px 0 #242022,
        -1.7px  0   0 #242022;
        
    }

#connect {
    font-size: clamp(2rem, 3vw, 10rem);
        text-shadow:
        -1.7px -1.7px 0 #242022,
         0   -1.7px 0 #242022,
         1.7px -1.7px 0 #242022,
         1.7px  0   0 #242022,
         1.7px  1.7px 0 #242022,
         0    1.7px 0 #242022,
        -1.7px  1.7px 0 #242022,
        -1.7px  0   0 #242022;
        border-width: 1.7px;
}

#chainchange {
    font-size: clamp(2rem, 3vw, 10rem);

    text-shadow:
        -1.7px -1.7px 0 #242022,
         0   -1.7px 0 #242022,
         1.7px -1.7px 0 #242022,
         1.7px  0   0 #242022,
         1.7px  1.7px 0 #242022,
         0    1.7px 0 #242022,
        -1.7px  1.7px 0 #242022,
        -1.7px  0   0 #242022;
    border-width: 1.7px;
}

#sidebutton {
    font-size: clamp(2rem, 3vw, 10rem);

    text-shadow:
        -1.7px -1.7px 0 #242022,
         0   -1.7px 0 #242022,
         1.7px -1.7px 0 #242022,
         1.7px  0   0 #242022,
         1.7px  1.7px 0 #242022,
         0    1.7px 0 #242022,
        -1.7px  1.7px 0 #242022,
        -1.7px  0   0 #242022;

    border-width: 1.7px;
}


#helpbutton {
    font-size: clamp(2rem, 1.9vw, 10rem);
    border-width: 1.7px;
    text-shadow:
        -1.7px -1.7px 0 #242022,
         0   -1.7px 0 #242022,
         1.7px -1.7px 0 #242022,
         1.7px  0   0 #242022,
         1.7px  1.7px 0 #242022,
         0    1.7px 0 #242022,
        -1.7px  1.7px 0 #242022,
        -1.7px  0   0 #242022;
}




#backbutton {

    font-size: clamp(2rem, 3vw, 10rem);
    transform: rotate(-3deg) skew(1deg) translateX(2.5em) translateY(-0.8em);
    border-width: 1.7px;
    text-shadow:
        -1.7px -1.7px 0 #242022,
         0   -1.7px 0 #242022,
         1.7px -1.7px 0 #242022,
         1.7px  0   0 #242022,
         1.7px  1.7px 0 #242022,
         0    1.7px 0 #242022,
        -1.7px  1.7px 0 #242022,
        -1.7px  0   0 #242022;
}
    }

@media only screen and (min-width: 1200px) {

#stroke {
        text-shadow:
        -2px -2px 0 #242022,
         0   -2px 0 #242022,
         2px -2px 0 #242022,
         2px  0   0 #242022,
         2px  2px 0 #242022,
         0    2px 0 #242022,
        -2px  2px 0 #242022,
        -2px  0   0 #242022;
    }

    #title {
        text-shadow:
        -2px -2px 0 #242022,
         0   -2px 0 #242022,
         2px -2px 0 #242022,
         2px  0   0 #242022,
         2px  2px 0 #242022,
         0    2px 0 #242022,
        -2px  2px 0 #242022,
        -2px  0   0 #242022;
    }

#connect {
    font-size: clamp(2rem, 3vw, 10rem);
    text-shadow:
        -2px -2px 0 #242022,
         0   -2px 0 #242022,
         2px -2px 0 #242022,
         2px  0   0 #242022,
         2px  2px 0 #242022,
         0    2px 0 #242022,
        -2px  2px 0 #242022,
        -2px  0   0 #242022;
        border-width: 2px;
}

#chainchange {
    font-size: clamp(2rem, 3vw, 10rem);

    text-shadow:
        -2px -2px 0 #242022,
         0   -2px 0 #242022,
         2px -2px 0 #242022,
         2px  0   0 #242022,
         2px  2px 0 #242022,
         0    2px 0 #242022,
        -2px  2px 0 #242022,
        -2px  0   0 #242022;
        border-width: 2px;
}

#sidebutton {
    font-size: clamp(2rem, 3vw, 10rem);

    text-shadow:
        -2px -2px 0 #242022,
         0   -2px 0 #242022,
         2px -2px 0 #242022,
         2px  0   0 #242022,
         2px  2px 0 #242022,
         0    2px 0 #242022,
        -2px  2px 0 #242022,
        -2px  0   0 #242022;

        border-width: 2px;
}

#helpbutton {
    font-size: clamp(2rem, 1.9vw, 10rem);
    border-width: 2px;
    text-shadow:
        -2px -2px 0 #242022,
         0   -2px 0 #242022,
         2px -2px 0 #242022,
         2px  0   0 #242022,
         2px  2px 0 #242022,
         0    2px 0 #242022,
        -2px  2px 0 #242022,
        -2px  0   0 #242022;
}

#backbutton {
    
    font-size: clamp(2rem, 3vw, 10rem);
    transform: rotate(-3deg) skew(1deg) translateX(2.5em) translateY(-0.8em); 
    border-width: 2px;
    text-shadow:
        -2px -2px 0 #242022,
         0   -2px 0 #242022,
         2px -2px 0 #242022,
         2px  0   0 #242022,
         2px  2px 0 #242022,
         0    2px 0 #242022,
        -2px  2px 0 #242022,
        -2px  0   0 #242022;
}
}

@media only screen and (max-height: 700px) {
    #helpbutton {
        font-size: 25px;

    }
}